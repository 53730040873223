nav {
  background: rgba(0, 0, 0, 0.3);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  right: 2%;
  bottom: 30%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
  opacity: 0;
}

nav a {
  background: transparent;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.1rem;
  cursor: pointer;
}

nav a:hover {
  background: rgba(0, 0, 0, 0.3);
}

nav a.active {
  background: var(--color-primary);
  color: var(--color-bg);
  cursor: pointer;
}

/*======== MEDIA QUERIES(MEDIUM DEVICES) ========*/
@media screen and (max-width: 1024px) {
  nav {
    padding: 0.5rem 0.9rem;
  }
}

@media screen and (max-width: 600px) {
  nav {
    display: none;
  }
}
