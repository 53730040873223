#about {
  /* scroll-snap-align: start; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.about__me {
  width: 100%;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0);
}

.about__content p {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  color: var(--color-light);
}
.about__container a:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  color: var(--color-white);
}

/*======== MEDIA QUERIES(MEDIUM DEVICES) ========*/
@media screen and (max-width: 1024px) {
  .about__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .about__me {
    width: 50%;
    margin: 0;
  }
  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

/*======== MEDIA QUERIES(SMALL DEVICES) ========*/
@media screen and (max-width: 600px) {
  .container.about__container {
    padding-top: 1rem;
  }
  .about__container {
    gap: 2rem;
  }
  .about__me {
    width: 65%;
    margin: 0;
  }
  .about__content {
    text-align: center;
  }
  .about__content p {
    margin: 1rem;
  }
}
