#portfolio {
  /* scroll-snap-align: start; */
  height: max-content;

  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}
.portfolio__item:hover {
  border-color: var(--color-primary-var);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin-top: 1rem;
  letter-spacing: 0.03rem;
}

.item-subtitle {
  margin-bottom: 1rem;
  color: var(--color-light);
  letter-spacing: 0.03rem;
}
.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.portfolio__item-cta a:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  color: var(--color-white);
}

/*======== MEDIA QUERIES(MEDIUM DEVICES) ========*/
@media screen and (max-width: 1024px) {
  .portfolio__container {
    gap: 1.2rem;
  }
}

/*======== MEDIA QUERIES(SMALL DEVICES) ========*/
@media screen and (max-width: 600px) {
  .portfolio__container {
    width: 90%;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .portfolio__item h3 {
    margin: 1rem 0 1rem;
  }
}
