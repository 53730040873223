.header {
  height: 100vh;
  /* padding-top: 7rem; */
  overflow: hidden;
  border: 1;
}
.header__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.header-title {
  display: flex;
  flex-direction: column;
}

.header-title h2 {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  color: #fff;

  opacity: 0;
  animation-name: greeting;
  animation-duration: 1.2s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes greeting {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header-title img {
  margin: 2.5rem 0;
  opacity: 0;
  animation-name: name;
  animation-duration: 1.2s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes name {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header-title h3 {
  letter-spacing: 0.1rem;
  font-size: 1.8rem;
  opacity: 0;
  animation-name: title;
  animation-duration: 2s;
  animation-delay: 2.6s;
  animation-fill-mode: forwards;
}

@keyframes title {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header-title p {
  font-size: 1.3rem;
}
.header__socials {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  align-items: center;
}

.header__socials::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/*======== MEDIA QUERIES(MEDIUM DEVICES) ========*/
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
  .header-title h2 {
    font-size: 1.5rem;
  }

  .header-title h1 {
    font-size: 3rem;
    margin: 2rem 0;
  }

  .header-title h3 {
    font-size: 1.2rem;
  }
}

/*======== MEDIA QUERIES(SMALL DEVICES) ========*/
@media screen and (max-width: 600px) {
  #header {
    height: 100vh;
    padding: 0 1.3rem;
  }

  .header__socials {
    display: none;
  }
  .header-title h2 {
    font-size: 1rem;
  }

  .header-title h1 {
    font-size: 2.2rem;
    margin: 1.3rem 0;
  }

  .header-title h3 {
    font-size: 1rem;
  }
}
