#experience {
  /* scroll-snap-align: start; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 2rem;
}

.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

.experience__backend {
  height: 17.7rem;
}

/*======== MEDIA QUERIES(MEDIUM DEVICES) ========*/
@media screen and (max-width: 1024px) {
  .experience__container {
    gap: 1.2rem;
  }
}

/*======== MEDIA QUERIES(SMALL DEVICES) ========*/
@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
    padding: 2rem;
  }
  .experience__container > div {
    /* width: 100%; */
    padding: 2rem 1rem;
    gap: 1rem;
    grid-template-columns: 1fr;
  }
}
